<template>
    <div class="ls-container" :class="marginType">
        <div class="ls-line"></div>
    </div>
</template>

<script>
export default {
   name: 'line-separator',

   props: { 
        marginType: String
   }
}
</script>


<style scoped>

.ls-container {
    padding-left: 5%;
    padding-right: 5%;
}

.ls-line {
    border-bottom: 3px solid var(--line-main);
}

.none {
    margin-top: 0vw;
    margin-bottom: 0vw;
}

.small {
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.medium {
    margin-top: 3vw;
    margin-bottom: 3vw;
}

.big {
    margin-top: 5vw;
    margin-bottom: 5vw;
}


</style>