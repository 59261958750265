<template>
    <div class="app">
        <banner></banner>
        <main-page></main-page>
    </div>
</template>




<script>
import Banner from '@/components/main/Banner.vue';
import MainPage from '@/pages/MainPage.vue';


export default {

    components: {
        Banner, MainPage
    },

    data() {
        return {

        }
    },

    methods: {
    
    }

   
    
}
</script>



<style>

/* Custom properties: */
:root {
    
    /* Backgrounds: */
    --bg-main: #f4f6f3;
    --bg-gray: #ccd1cd;

    /* Menu: */
    --menu-passive:  #aec2ba;
    --menu-onhover:  #4e6846;
    --menu-selected: #1d2f18;

    /* Lines: */
    --line-main: #aec2ba66;

    /* Text: */
    --text-l1: #16331C;
    --text-l2: #335239;
    --text-l3: #6E8573;

    /* Text weight: */
    --weight-l1: 600;
    --weight-l2: 500;
    --weight-l3: 400;
    --weight-l4: 300;

    /* Buttons: */
    --button-passive: #8DB896;
    --button-onhover: #f4f6f3;

    /* Font sizes: */
    --font-button:  clamp(0.70rem, 1.00vw, 1.50rem);
    --font-menu:    clamp(1.50rem, 4.00vw, 5.00rem);
    --font-caption: clamp(0.75rem, 3.00vw, 4.00rem);
    --font-sub:     clamp(0.80rem, 2.90vw, 3.60rem);
    --font-text:    clamp(0.75rem, 2.40vw, 2.80rem);
    --font-desc:    clamp(0.70rem, 2.20vw, 2.80rem);
}

/* Default settings: */
* {
    margin: 0px;
    padding: 0px;
}

html, body {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Dosis', sans-serif;
    background-color: var(--bg-main);
}

.app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: clamp(300px, 100vw, 100vw);
}

</style>