<template>
    <div class="pr-container">
        <prices-nav
            :selectedTopic="selectedTopic"
            @topic-changed="OnTopicChanged"
        ></prices-nav>

        <styles-topic v-show="selectedTopic==='Styles'"></styles-topic>

    </div>
</template>

<script>
import PricesNav from '@/components/prices/components/PricesNav.vue';
import StylesTopic from '@/components/prices/topics/StylesTopic.vue'



export default {

    components: {
        PricesNav, StylesTopic
    },

    data() {
        return {
           selectedTopic: 'Styles'
        }
    },

    methods: {
         OnTopicChanged (newTopic) {
            this.selectedTopic = newTopic;
        }
    }
}
</script>


<style scoped>


</style>