<template>
    <div class="st-container">
         <side-image
            :imageName="'sketch'"
            :imageCaption="'SKETCH - 30 EUR'"
            :imageDescription="'Clean outlines, no colors or a touch of monochrome. Simple and expressive, capturing the basics'"
            :imageLink="''"
            :imageSide="'right'"
        ></side-image>

         <side-image
            :imageName="'colored'"
            :imageCaption="'COLORED - 35 EUR'"
            :imageDescription="'Vibrant sketches brought to life with colors. Minimal shades, added sparingly for a touch of depth when it fits the artistic vision'"
            :imageLink="''"
            :imageSide="'left'"
        ></side-image>

        <side-image
            :imageName="'lineart'"
            :imageCaption="'LINE ART - 50 EUR'"
            :imageDescription="'Smooth lines, clean colors, and a step up in shading. More depth without losing the crisp look'"
            :imageLink="''"
            :imageSide="'right'"
        ></side-image>

        <side-image
            :imageName="'cellshade'"
            :imageCaption="'CELL SHADE - 65 EUR'"
            :imageDescription="'Crisp lines, vibrant colors, and dynamic shading. Elevate your art with a stylish touch - distinct and eye-catching'"
            :imageLink="''"
            :imageSide="'left'"
        ></side-image>

    </div>
</template>

<script>
import SideImage from '@/components/prices/components/SideImage.vue';

export default {

    components: {
        SideImage
    },

    data() {
        return {
            
        }
    },

    methods: {
        
    }
}
</script>


<style scoped>



</style>