<template>
    <div class="mp-container">
        <nav-bar
            @menu-selected="OnMenuChanged"
            :selectedMenu="selectedMenu"
        ></nav-bar>

        <info-page v-show="selectedMenu == 'info'"></info-page>
        <prices-page v-show="selectedMenu == 'prices'"></prices-page>

    </div>
</template>

<script>
import NavBar from '@/components/main/NavBar.vue';
import InfoPage from '@/pages/InfoPage.vue';
import PricesPage from '@/pages/PricesPage.vue';

export default {

    components: {
        NavBar, InfoPage, PricesPage
    },

    data() {
        return {
            selectedMenu: 'info'
        }
    },

    watch: {
        $route () {
            this.OnRouteChanged();
        }
    },

    methods: {
         OnRouteChanged () {

            let cp = this.$route.path;

            if (cp == "/info")
            {

            }
            else if (cp == "/prices")
            {

            }
            else if (cp == "/tos")
            {

            }
            else {
                cp = "/info";
                this.$router.push({ path: cp });
            }

            this.selectedMenu = cp.replace('/', '');
        },

        OnMenuChanged (menu) {
            this.selectedMenu = menu;
        }
    }
}
</script>


<style scoped>

.mp-container {
    width: clamp(300px, 100vw, 1600px);
}

</style>