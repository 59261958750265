<template>
    <div class="di-container" @click="OpenLink(imageLink)">
        <div class="di-image-holder">
            <img class="idle" :src="require('@/assets/info/icons/idle/' + imageName + '.webp')" :alt="'Clickable image of a ' + imageName + ' logo'">
            <img class="colored" :src="require('@/assets/info/icons/colored/' + imageName + '.webp')" :alt="'Clickable image of a ' + imageName + ' logo'">
        </div>
    </div>
</template>

<script>
export default {
   name: 'double-image',

   props: { 
        imageName: String,
        imageLink: String
   },

   methods: {
        OpenLink(link) {
            window.open(link, "_blank"); 
        }
   }
}
</script>


<style scoped>

.di-image-holder {
    position: relative;
    padding-bottom: 100%;
}

img {
    position:absolute;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
}

.colored, .idle {
    transition: 0.6s ease-in-out;
}

.colored {
    opacity: 0;
}

.di-container:hover .idle {
    opacity: 0;
}

.di-container:hover .colored {
    opacity: 1;

}







</style>