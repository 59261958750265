<template>
    <div class="br-container">
        <img src="@/assets/navbar/banner.webp" alt="Banner with lots of tree leaves peeking from above">
    </div>
</template>

<style scoped>

.br-container, img{
    width: 100%;
}

</style>