<template>
    <div class="io-container">



        <div class="io-top">
            <div class="io-block io-credit"><img src="@/assets/info/credit.webp" alt="Credit image of an artist's name with doodle art of their character who created the profile picture"></div>
            <div class="io-block"></div>
            <div class="io-block io-caption"><p>{{ infoCaption }}</p></div>
            <div class="io-block"></div>
            <div class="io-block"></div>
        </div>



        <div class="io-mid">
            <div class="io-intro">
                <div class="io-pfp">
                    <img src="@/assets/info/pfp.webp" alt="Image of artist's character">
                </div>
                <div class="io-text">
                    <p>{{ infoText }}</p>
                </div>
            </div>
            <div class="io-icons">
                <double-image class="double-image" :imageName="'twitter'" :imageLink="'https://twitter.com/skandiniwi'"></double-image>
                <double-image class="double-image" :imageName="'bluesky'" :imageLink="'https://twitter.com/skandiniwi'"></double-image>
                <double-image class="double-image" :imageName="'furaffinity'" :imageLink="'https://twitter.com/skandiniwi'"></double-image>
                <double-image class="double-image" :imageName="'telegram'" :imageLink="'https://twitter.com/skandiniwi'"></double-image>
                <double-image class="double-image" :imageName="'discord'" :imageLink="'https://twitter.com/skandiniwi'"></double-image>
            </div>
        </div>



        <div class="io-bot">

        </div>



    </div>
</template>

<script>
export default {
    data() {
        return {
            infoCaption: 'SKANDINIWI',
            infoText: 'Hey, I\'m Skadiwi! A full-time digital artist with a passion for drawing cute characters. I practice every day, working towards my dream of becoming a super talented artist - it\'s not just a goal, but my daily mission and sole source of income.'
        }
    },

    methods: {
        
    }
}
</script>


<style scoped>

.io-container, .io-top, .io-mid, .io-intro, .io-caption, .io-text, .io-icons {
    display: flex;
}

.io-container, .io-mid {
    flex-direction: column;
}

.io-caption {
    justify-content: center;
    align-items: flex-end;
}

.io-pfp {
    width: 40%;
}

.io-caption {
    font-size: var(--font-caption);
    font-weight: var(--weight-l2);
    color: var(--text-l1);
}

.io-text {
    width: 60%;
    justify-content: center;
    align-items: center;
}

.io-text p {
    text-align: left;
    font-size: var(--font-text);
    color: var(--text-l3);
    font-weight: var(--weight-l4);
    margin-left: 5%;
    margin-right: 5%;
}

img {
    width: 100%;
}

.io-block {
    width: 20%;
}

.io-icons img {
    width: 15%;
}

.io-icons .double-image {
    width: 10%;
}

.io-icons {
    justify-content: center;
}

.io-mid {
    background-color: var(--bg-gray);
    border-radius: 25px;
    overflow: hidden;
}


.io-bot {
    height: 10vw;
}




</style>