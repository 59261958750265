<template>
    <div class="nb-container">
        <div class="nb-menus">
            <p @click="SetActive('info')" :class="{'nb-active': selectedMenu == 'info'}">INFO</p>
            <p @click="SetActive('prices')" :class="{'nb-active': selectedMenu == 'prices'}">PRICES</p>
            <p @click="SetActive('tos')" :class="{'nb-active': selectedMenu == 'tos'}">T.O.S</p>
        </div>
      <line-separator :marginType="'none'"></line-separator>
    </div>
    
</template>

<script>
export default {
    props: {
        selectedMenu: String
    },
    
    methods: {
        SetActive(menu) {
            this.$emit('menu-selected', menu);
            this.$router.push({ path: `/${menu}` });
        }
    }
}
</script>


<style scoped>

.nb-menus {
    display: flex;
    justify-content: space-around;
}

p {
    font-weight: var(--weight-l2);
    font-size: var(--font-menu);
    color: var(--menu-passive);
    transition: 0.4s ease-in-out, color 0.4s ease-in-out;
}

p:hover {
    color: var(--menu-onhover);
    cursor: pointer;
}

.nb-active {
    color: var(--menu-selected);
}

</style>