<template>
    <button class="sd-button">
        <slot class="sd-text"></slot>
    </button>
</template>

<script>
export default {
   name: 'styled-button'
}
</script>


<style>
.sd-button {
    height: clamp(1.5rem, 2.5vw, 3rem);
    margin-left: 1.6vw;
    margin-right: 1.6vw;
    padding-left: 1.6vw;
    padding-right: 1.6vw;

    border-radius: 25px;
    border: 1px var(--button-passive) solid;

    font-size: var(--font-button);
    background-color: var(--button-onhover);
    color: var(--button-passive);

    transition-duration: 0.4s;
}


.sd-button:hover {
    cursor: pointer;
    background-color: var(--button-passive);
    color: var(--button-onhover);
}


</style>