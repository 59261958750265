<template>
    <div class="pb-container">
       <div class="pb-row-01">
            
            <styled-button @click="OnTopicChanged(style1)" :class="{selected: selectedTopic===style1}">{{ style1 }}</styled-button>
            <styled-button @click="OnTopicChanged(style2)" :class="{selected: selectedTopic===style2}">{{ style2 }}</styled-button>
            <styled-button @click="OnTopicChanged(style3)" :class="{selected: selectedTopic===style3}">{{ style3 }}</styled-button>
       </div>
       <div class="pb-row-02">
            <styled-button @click="OnTopicChanged(style4)" :class="{selected: selectedTopic===style4}">{{ style4 }}</styled-button>
            <styled-button @click="OnTopicChanged(style5)" :class="{selected: selectedTopic===style5}">{{ style5 }}</styled-button>
       </div>
    </div>
</template>

<script>



export default {

    props: {

    },

    data() {
        return {
            selectedTopic: 'Styles',
            style1: 'Reference Sheets',
            style2: 'Styles',
            style3: 'Character Design',
            style4: 'Stickers',
            style5: 'Comics'
        }
    },

    methods: {
        OnTopicChanged(newTopic) {
            this.selectedTopic = newTopic;
            this.$emit('topic-changed', newTopic);
        }
    }
}
</script>


<style scoped>

.pb-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 4vw;
    padding-top: 4vw;
}

.pb-row-01 {
    margin-bottom: 2vw;
}

.selected {
    background-color: var(--button-passive);
    color: var(--button-onhover);
}


</style>